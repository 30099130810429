<script setup lang="ts">
import { onMounted, ref } from "vue";

type Theme = "light" | "dark";

const theme = ref<Theme>();

const getTheme = () =>
  document.documentElement.getAttribute("data-theme")! as Theme;

onMounted(() => {
  theme.value = getTheme();
});

const toggle = () => {
  const currentTheme = getTheme();

  const _theme = currentTheme === "light" ? "dark" : "light";

  document.documentElement.setAttribute("data-theme", _theme);
  theme.value = _theme;
};
</script>

<template>
  <button
    @click="toggle"
    class="flex items-center justify-start text-base font-subheading"
  >
    <span class="sr-only">Toggle color mode</span>
    <div class="mr-2 cursor-pointer uppercase text-sm">Go light</div>

    <div class="w-12 rounded-full border-2 border-utility-black p-[2px]">
      <div
        :class="[theme === 'dark' && 'translate-x-full']"
        class="h-5 w-5 rounded-full bg-utility-black transition-transform"
      ></div>
    </div>

    <div class="ml-2 cursor-pointer uppercase text-sm">Go dark</div>
  </button>
</template>
