import { login_path } from "../../auth/usecases/login_path";
import type { AuthUser } from "../../auth/users";

type LoginUrl = Parameters<typeof login_path>[0];

export interface Link {
  text: string;
  href: string;
}

const auth = (url: LoginUrl): Link => ({
  text: "Sign in",
  href: login_path(url),
});

const LINKS = {
  account: { text: "Your account", href: "/account" },
  leaderboards: { text: "Leaderboards", href: "/leaderboards/" },
  statistics: { text: "Statistics", href: "/statistics/" },
  quizzes: { text: "Quizzes", href: "/quizzes/" },
  puzzles: { text: "Puzzles", href: "/puzzles/" },
  premium: { text: "Get Premium", href: "/premium/" },
  selects: { text: "Selects", href: "/selects/" },
  gift: { text: "Gift the quiz", href: "/gift/" },
} satisfies Record<string, Link>;

const pickLinks = (keys: Array<keyof typeof LINKS>): Array<Link> => {
  const links: Array<Link> = [];

  for (const key of keys) {
    links.push(LINKS[key]);
  }

  return links;
};

const shared = pickLinks(["quizzes", "puzzles", "selects", "gift"]);

export const get_links = (role: AuthUser["role"], url: LoginUrl): Array<Link> =>
  ({
    unknown: [auth(url), ...pickLinks(["premium"]), ...shared],
    anonymous: [auth(url), ...pickLinks(["premium"]), ...shared],
    trial: [...pickLinks(["account", "premium"]), ...shared],
    premium: [
      ...pickLinks(["account", "leaderboards", "statistics"]),
      ...shared,
    ],
  })[role];
