<script setup lang="ts">
import { computed } from "vue";
import ThemeSwitch from "~/components/layout/ThemeSwitch.vue";
import type { AuthUser } from "~/lib/auth/users";
import { get_links, type Link } from "~/lib/navigation/usecases/get_links";
import { use_menu_drawer } from "~/stores/menu-drawer";
import CloseIcon from "~icons/sqt/close";

// TODO: use radix-vue

const props = defineProps<{
  role: AuthUser["role"];
  name?: string;
  loginPath: string;
}>();

const { is_open, close } = use_menu_drawer();

const links = computed(() => get_links(props.role, window.location));

const secondaryLinks: Array<Link> = [
  {
    text: "Shop merch",
    href: "/shop/",
  },
  {
    text: "FAQ",
    href: "/frequently-asked-questions/",
  },
  {
    text: "Blog",
    href: "/blog/",
  },
  {
    text: "About",
    href: "/about/",
  },
  {
    text: "Contact",
    href: "/contact/",
  },
  {
    text: "Privacy policy",
    href: "/privacy-policy/",
  },
  {
    text: "Instagram",
    href: "https://instagram.com/satquiztime",
  },
];
</script>

<template>
  <div
    v-cloak
    :class="[is_open ? 'translate-y-0' : 'translate-y-[101%]']"
    class="menu-drawer fixed bottom-0 left-0 right-0 z-[100] h-[95%] w-full rounded-t-2xl text-utility-black duration-300 ease-in-out lg:max-w-2xl bg-theme-yellow lg:mx-auto"
  >
    <button
      class="button button-accent-contrast button-icon fixed top-4 right-4 p-1.5"
      @click="close"
    >
      <CloseIcon class="h-6 w-6" />
    </button>

    <div class="h-full w-full overflow-y-scroll px-4 py-12">
      <div class="mt-5 text-center" v-if="name">
        <h2 class="font-heading text-5xl lg:text-7xl">Hi, {{ name }}!</h2>
        <div class="mt-5">
          <a
            href="/account"
            class="button button-accent-contrast inline-block text-lg"
          >
            Manage account
          </a>
        </div>
      </div>

      <ul class="mt-8 space-y-2 font-heading text-center text-4xl lg:text-6xl">
        <li v-for="(link, index) in links" :key="index">
          <a :href="link.href">{{ link.text }}</a>
        </li>
      </ul>

      <ul class="mt-10 space-y-1 text-lg text-center">
        <li class="mb-5" v-if="!name">
          <a :href="loginPath" role="button">Sign in</a>
        </li>
        <li v-for="(link, index) in secondaryLinks" :key="index">
          <a :href="link.href">{{ link.text }}</a>
        </li>
      </ul>

      <ThemeSwitch class="my-10 mx-auto" />
    </div>
  </div>
  <Transition
    enter-from-class="opacity-0"
    enter-active-class="transition-opacity duration-300 ease"
    enter-to-class=""
    leave-from-class=""
    leave-active-class="transition-opacity duration-300 ease"
    leave-to-class="opacity-0"
  >
    <button
      v-show="is_open"
      @click="close"
      role="button"
      class="fixed inset-0 z-[99] bg-background/80"
    ></button>
  </Transition>
</template>
